import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  smallMarginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const SprechstundePlusArtikelPage = () => {
  return (
    <Layout pageTitle="SprechstundePlusArtikel" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>Artikel - 30. August 2023</p> 
                <h1>Sprechstunden – digitalisiert, automatisiert, effizient</h1>
               
                <p className="text-medium">
                Am Swiss eHealth Forum vom Kunden präsentiert: Wer von Anfang an gut informiert ist, ist auch für alle weiteren Schritte bestens gerüstet.</p>

                <p>Anästhesie-Sprechstunden wollen optimal vorbereitet sein und alle Informationen müssen rechtzeitig bereitliegen. Dieses Vorgehen war bislang mit einem immensen administrativen Aufwand verbunden, 
                  damit der Anästhesist bzw. die Anästhesistin alle benötigten Unterlagen für die Sprechstunde aufbereitet im Zugriff hat.</p>

                  <div className={buttonGroup}><a href="https://www.clinicum.ch/images/getFile?t=ausgabe_artikel&f=dokument&id=2948"className="button-outline blue" target="_blank" rel="noreferrer"> Zum vollständigen Artikel </a> </div>

                  <div className={smallMarginTopSubstitute}>
                  <StaticImage
                    src="../assets/HealthForumBild.jpg"
                    alt="Screenshot ANA-Cockpit Hirslanden"
                    placeholder="none"
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
              
                  </div>
            </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default SprechstundePlusArtikelPage;
